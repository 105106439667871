var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

$(window).on("load", function() {
	$("#loader").fadeOut(500);
});

$(document).ready(function () {

	var disableScrolling = function(){
		var x=window.scrollX;
		var y=window.scrollY;
		window.onscroll=function(){window.scrollTo(x, y);};
	}
	var enableScrolling = function(){
	window.onscroll=function(){};
	}
	window.onbeforeunload = function () {
	//window.scrollTo(0, 0);
	}

	function onResize(){
		//if(isMobile.any() && $("input").is(":focus")==true){
		if(isMobile.any()){
			return;
		}
		winWT = $(window).innerWidth();
		winHT = $(window).innerHeight();
		//$("#screen-home").css({minHeight:winHT});
	}
	
	$(window).resize(onResize)
	onResize();

	if(winWT < 600){
		// Listen for orientation changes
		function onOrientationChange() {
			if (window.orientation == 90 || window.orientation == -90) {
				$(".rotate-msg").css("display", "block");
			} else {
				$(".rotate-msg").css("display", "none");
			}
		}
		window.addEventListener("orientationchange", onOrientationChange, false);
		onOrientationChange();
	}

	if (winWT < 1023) {
		$('.navigation ul li a').click(function () {
			$('.menu').trigger('click');
		});
		gsap.set(".navigation ul > li", { opacity: 0, x:50, ease:Power2.easeInOut });
    }else{
		gsap.set(".navigation ul > li", { opacity: 1, x:0});
	}
	
	$('.menu').on('click',function () {
		$(this).toggleClass("toggle");
		if ($('.menu').hasClass("toggle")) {
			$('body').unbind('click')
			//$('body').addClass("no-scroll")
			//disableScrolling();
			//$(".nav-overlay").fadeIn(100);
			gsap.to(".navigation", {duration: 0.1, right:"0%",  ease:Power0.easeNone})
			gsap.to(".navigation ul > li", {duration: 0.2,opacity:1, x:0, stagger: {
				amount: 0.5, 
				grid: 'auto', 
				ease: '"elastic.in"',
				stagger: 0.1
			}});
			
		}else {
			//enableScrolling();
			//$('body').removeClass("no-scroll")
			//$(".nav-overlay").fadeOut(100);
			gsap.to(".navigation", {duration: 0.1, right:"-100%",  ease:Power0.easeNone})
			gsap.to(".navigation ul > li", {duration: 0.2, opacity:0, x:50, stagger: {
				amount: 0.5, 
				grid: 'auto', 
				ease: '"elastic.in"',
				stagger: 0.1
			}});
		}
	});

	

	var navPosition = "down";
	function headerWipe(){
		if($('.menu').hasClass("toggle")){
			return;
		}
		if ($(this).scrollTop() > 10 && navPosition == "down") {
			navPosition = "up";
			$("header").addClass("sticky");
		}
		else if($(this).scrollTop() < 10 && navPosition == "up"){
			navPosition = "down";
			$("header").removeClass("sticky");
		}
	}
	if (winWT >= 1025) {
		headerWipe();
	}

	$(window).bind('scroll', function () {
		if (winWT >= 1025) {
			headerWipe();
		}
	});

	$(function() {
		AOS.init({once: true});
   });

   $(".slimmenu").slimmenu({
	resizeWidth: "1024",
	collapserTitle: "",
	animSpeed:200,
	indentChildren: false,
	childrenIndenter: "",
});
	
	
});